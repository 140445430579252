import $ from 'jquery'

$(() => {

    $('.tabs .tab').on('click', (e) => {
        e.preventDefault()

        const parent = $(e.target).closest('.tabs'),
            activeTab = $(e.target).closest('.tab'),
            parentId = parent.data('id'),
            id = activeTab.data('id'),
            wrap = $(`.tabs-container[data-id="${parentId}"]`)

        window.location.hash = id;

        parent.find('.tab').removeClass('active')
        activeTab.addClass('active')

        wrap.find('.tab-content').removeClass('active')
        wrap.find(`.tab-content[data-target="${id}"]`).addClass('active')
    })

    if (window.location.hash && $('.tabs').length > 0) {
        const hash = window.location.hash.substring(1),
            tab = $(`.tabs .tab[data-id="${hash}"]`)

        if (tab.length > 0) {
            tab.trigger('click')
        }
    }
})
