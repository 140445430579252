import $ from 'jquery'

$(() => {

    if ($('.avatar.editable').length > 0) {

        $('.profile-image-field').change(function() {
            let reader = new FileReader()

            reader.onloadend = (e) => {
                $('#profile-image').attr('src', reader.result)
            }

            reader.readAsDataURL(this.files[0]);
        });

    }
})
