import $ from 'jquery'
import moment from 'moment-timezone'

$(() => {
    /*
     * Use Moment Timezone to detect the user's timezone.
     *
     * @see
     *     https://momentjs.com/timezone/docs/#/using-timezones/guessing-user-timezone/
     *     for documentation of moment.tz.guess()
     */
    const timezone = moment.tz.guess()

    /*
     * Wait until page is ready.
     */
    $.when($.ready).then(() => {
        /*
         * Find any elements with "prefill-timezone" class, and pre-fill
         * them with the detected timezone.
         *
         * Only do this if the user hasn't already selected a timezone.
         */
        $('.prefill-timezone').each(function () {
            let field = $(this)
            // Only set the timezone if the user hasn't chosen already.
            if (!field.val()) {
                // Set the field to our best guess at the user's timezone.
                field.val(timezone)
            }
        })
    })
})
